// // Cache all parallax elements to avoid further queries
// const parallaxContainers = document.querySelectorAll('.parallax-container');

// // Increase size of element in order to prevent any exposed gap on scroll
// const adjustLayerHeight = () => {
//   parallaxContainers.forEach(container => {
//     const containerHeight = container.offsetHeight * 1.33;
//     const layers = container.querySelectorAll('.parallax-layer');
//     layers.forEach(layer => {
//       layer.style.height = containerHeight + 'px';
//     });
//   });
// };

// // Prevent parallax from running until item is close to viewport to help elements further down the page
// const isElementInViewport = el => {
//   const rect = el.getBoundingClientRect();
//   return rect.top <= window.innerHeight + 50 && rect.bottom >= 0;
// };

// // Parallax movement:
// // Multiple layers within the same container will offset speeds from each other based on order.
// // Each parallax container moves relative to where it is in the rendered HTML document
// const parallaxScroll = () => {
//   const parallaxScrollTop = window.scrollY;
//   parallaxContainers.forEach(container => {
//     const distanceFromTop = container.offsetTop + 1000;
//     if (isElementInViewport(container)) {
//       const scrollDistance = parallaxScrollTop - distanceFromTop;
//       const layers = container.querySelectorAll('.parallax-layer');
//       layers.forEach((layer, index) => {
//         const yPos = Math.ceil(scrollDistance * (index + 1) * 0.2 / 2);
//         layer.style.transform = `translateY(${yPos}px)`;
//       });
//     }
//   });
// };

// // Listen & run relevant functions on load, scroll, and resize
// window.addEventListener('load', () => {
//   const parallaxContainersExist = parallaxContainers.length > 0;
//   if (parallaxContainersExist) {
//     setTimeout(() => {
//       adjustLayerHeight();
//       parallaxScroll();
//       window.addEventListener('resize', adjustLayerHeight);
//       window.addEventListener('scroll', parallaxScroll);
//     }, 1000);
//   }
// });

const parallaxContainers = document.querySelectorAll('.parallax-container');
let pageStabilized = false;

// Set layer heights on load
const adjustLayerHeight = () => {
  parallaxContainers.forEach(container => {
    const containerHeight = container.offsetHeight * 1.33;
    const layers = container.querySelectorAll('.parallax-layer');
    layers.forEach(layer => {
      if (!layer.classList.contains('transparent-layer')) {
        layer.style.height = containerHeight + 'px'; // Apply height only if not transparent-layer
      }
    });
  });
};

// Check if element is in viewport
const isElementInViewport = el => {
  const rect = el.getBoundingClientRect();
  return rect.top <= window.innerHeight + 50 && rect.bottom >= 0;
};

// Run parallax only after the layout is stable
const parallaxScroll = () => {
  if (!pageStabilized) return;

  const parallaxScrollTop = window.scrollY;
  parallaxContainers.forEach(container => {
    const rect = container.getBoundingClientRect();
    const distanceFromTop = parallaxScrollTop + rect.top;

    if (isElementInViewport(container)) {
      const scrollDistance = parallaxScrollTop - distanceFromTop;
      const layers = container.querySelectorAll('.parallax-layer');
      layers.forEach((layer, index) => {
        const yPos = scrollDistance * (index + 1) * 0.2 / 2;
        layer.style.transform = `translateY(${yPos}px)`;
      });
    }
  });
};

// Stabilize layout before running the parallax effect
const stabilizeLayout = () => {
  // Wait for several frames to allow the layout to stabilize
  let frames = 0;
  const checkStabilized = () => {
    frames++;
    if (frames < 10) {
      requestAnimationFrame(checkStabilized);
    } else {
      pageStabilized = true; // Mark as stabilized after several frames
      parallaxScroll(); // Initial call to set positions
    }
  };
  requestAnimationFrame(checkStabilized);
};

// Run on load, scroll, and resize
window.addEventListener('load', () => {
  adjustLayerHeight();
  stabilizeLayout();
  window.addEventListener('resize', adjustLayerHeight);
  window.addEventListener('scroll', parallaxScroll);
});
